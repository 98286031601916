import React from "react";
import ReactPlayer from "react-player";
import { find } from "lodash";
import AppContainer from "../AppContainer";
import { navigateTo } from "../../utils/location";
import ThankYouIcon from "../icons/ThankYou.svg";
import styles from "./index.module.scss";

export default class extends React.Component {
  reset = () => {
    this.props.resetDonateGift();
    navigateTo("/");
  };

  render() {
    const {
      T,
      keyboardOpen,
      user: {
        loggedIn,
        userDetails,
        donateGiftState: { selectedCharityId, selectedCharityDonationTotal },
      },
      pageContext: {
        charities,
        business: { name },
      },
      appStyles = {},
    } = this.props;

    const charity = find(
      charities,
      (charity) => charity.id === selectedCharityId,
    );
    const { GiftActionTitle } = appStyles;

    return (
      <>
        <AppContainer.Content appStyles={appStyles}>
          <div className={styles.Header}>
            {loggedIn && (
              <h2 style={{ ...GiftActionTitle, margin: 16 }}>
                {T(`Thanks for Your Donation, ${userDetails.data.name}!`)}
              </h2>
            )}
            {selectedCharityDonationTotal && (
              <p
                style={{
                  margin: "32px 20px",
                  textAlign: "center",
                  fontSize: 10,
                  fontWeight: "bold",
                }}
              >
                {T(
                  `${
                    selectedCharityDonationTotal.numberOfDonationsToCharity
                  } donations were made by ${name} family members`,
                )}
              </p>
            )}
          </div>

          {selectedCharityId && (
            <div className={styles.VideoPlayerWrapper}>
              <div className={styles.VideoPlayerHeader}>
                {T(`A personal thanks from ${charity.name}`)}
              </div>
              {charity.videoUrls[0] ? (
                <div className={styles.VideoPlayer}>
                  <ReactPlayer
                    url={charity.videoUrls[0]}
                    width="100%"
                    height="100%"
                  />
                </div>
              ) : (
                <div className={styles.ThankYouIconWrapper}>
                  <ThankYouIcon />
                </div>
              )}
            </div>
          )}
        </AppContainer.Content>
        <AppContainer.Footer
          appStyles={appStyles}
          relativePosition={keyboardOpen}
          ref={(el) => {
            this.footer = el;
          }}
          center
          height={true}
        >
          <AppContainer.Footer.Button
            appStyles={appStyles}
            center
            onClick={this.reset}
          >
            {T("Back to Home Screen")}
          </AppContainer.Footer.Button>
        </AppContainer.Footer>
      </>
    );
  }
}
